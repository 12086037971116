import Container from "../components/container";
import SEO from "../components/seo";
import React from "react";
import Layout from "../containers/layout";
import WorkCopy from "../components/work-copy";

const WorkPage = () => {
  return (
    <Layout>
      <SEO title="work" />
      <Container>
        <WorkCopy />
      </Container>
    </Layout>
  );
};

export default WorkPage;
