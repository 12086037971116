import React from "react";
import * as styles from "./work-copy.module.css";

const WorkCopy = () => {
  return (
    <div className={styles.root}>
      <h1>My Projects</h1>
      <hr />
      <section id="personal-project">
        <h2>Baker&apos;s Log @ Personal</h2>
        <p>
          A personal project that is currently still in development of an MVP.
          The app is a baker&apos;s log book to record data and notes for
          different bakes; including, bread, cakes, pastries, etc.
        </p>
        <ul>
          <li>Backend: Go, GraphQL, MongoDB</li>
          <li>Frontend: NextJS</li>
        </ul>
      </section>
      <section id="heard-and-smith">
        <h2>Client Center @ Heard and Smith (current)</h2>
        <p>
          An internal project build for Heard and Smith socila security law
          firm. This app combines 3 legacy applications into one flexible and
          modern web app. Call center specialists use this console to receive
          inbound and outbound calls, console allows users to input new clients
          and search through existing clients, and users use the intake wizard
          to screen new clients for acceptance. The intake wizard is a 13 step
          form, which allows the user to save and exit at any step.
        </p>
        <ul>
          <li>
            Backend: AWS API Gateway, AWS Lambda (python), AWS Connect, PHP (on
            an EC2 instance), MS SQL
          </li>
          <li>Frontend: ReactJS</li>
        </ul>
      </section>
      <section id="somis">
        <h2>SOMIS @ Premier Digital Designs</h2>
        <p>
          A project I worked on the MVP for with Premier Digital Designs. The
          app is a field service management software. The parts that I was a
          part of include: dispatching jobs, scheduling, inventory for vans and
          warehouse, invoicing, estimates, and customer management linked
          through Quickbooks API.
        </p>
        <ul>
          <li>Backend: ASP.NET Core (C#), MySQL</li>
          <li>Frontend: ASP.NET Core Razor Pages</li>
        </ul>
      </section>
    </div>
  );
};

export default WorkCopy;
